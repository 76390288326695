<template>
  <div>
    <section class="bg-home d-flex align-items-center" style="height: auto;">
      <div class="container">
        <div class="row mt-5 justify-content-center">
          <div class="col-lg-12 text-center mt-0 mt-md-5 pt-0 pt-md-5 mb-0 mb-md-5 pb-0 pb-md-5">
            <div class="title-heading">
              <p class="heading title-dark mt-3 mb-0">{{ title }}</p>
              <p class="heading title-dark mt-0 mb-3 text-primary">{{ organizationName }}</p>
              <div class="subcribe-form mt-4 pt-2">
                <form @submit.stop.prevent="search">
                  <div class="form-group">
                    <input
                        v-model="searchTerm"
                        class="border bg-white rounded-lg"
                        style="opacity: 0.85;" required
                        placeholder="Localizar Processo..."
                    >
                    <b-button @click="search" class="btn-pills btn-primary">Buscar</b-button>
                  </div>
                </form>
              </div>
              <p class="para-desc mx-auto text-dark font-size-xs" style="font-size: 15px !important;">Encontre Pregões, Dispensas, Concorrências, Leilões e Credenciamentos, tudo com total transparência, acompanhe em tempo real a Sala de Disputa de todos os procedimentos. </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section bg-soft-primary border-bottom pt-5 pb-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title">
              <h4 class="title mb-0">Últimas Publicações</h4>
            </div>
          </div>
        </div>
        <div class="row">
          <div v-if="processes.length === 0" class="col-12 text-center py-5">
            <h4>Nenhuma publicação realizada.</h4>
          </div>
          <div v-else class="col-12">
            <carousel
              id="customer-testi"
              ref="carousel"
              dir="ltr"
              :per-page="3"
              class="owl-carousel owl-theme"
              :autoplay="true"
              :loop="true"
            >
              <slide
                v-for="(process, index) in processes"
                :key="`lot-${index}`"
                style="padding-top: 10px; padding-right: 10px"
              >
                <div style="min-height: 190px; max-height: 190px" class="card event-schedule rounded border pr-2 card-carousel-media">
                  <div class="card-body px-2">
                    <div class="media">
                      <ul class="date text-center text-primary mr-1 mb-0 list-unstyled">
                        <li class="day font-weight-bold mb-2">{{ date_to_br_day(process.opening_date) }}</li>
                        <li class="month font-weight-bold">{{ date_to_br_month(process.opening_date).toUpperCase() }}</li>
                      </ul>
                      <div class="media-body content">
                        <div class="mb-0">
                          <span class="text-dark font-weight-bold card-carousel-media-title">{{ process.modality  }} nº {{ process.bidding_number }}</span>
                        </div>
                        <div class="mt-0">
                          <small class="badge badge-pill" :class="process.bg_class">{{ process.status }}</small>
                        </div>
                        <p style="min-height: 65px" class="text-muted small text-limit-lines-3 mb-0">
                          <span class="text-dark font-weight-bold">Objeto:</span> {{ process.object_details }}
                        </p>
                        <div>
                          <a
                            target="_blank"
                            :href="`${urlPlataform}/pesquisa/${process.id}`"
                            class="text-primary text-hover-primary"
                          >
                            <span class="btn btn-sm btn-outline-primary mouse-down float-right">Ver mais</span>
                          </a>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </slide>

            </carousel>
          </div>
          <!--end col-->
        </div>
      </div>
    </section>

    <section class="section pt-5 pb-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-0 pb-0">
              <h4 class="title text-primary mb-4">Escolha o melhor plano</h4>
            </div>
          </div>
        </div>
        <Pricing />
      </div>
    </section>

    <div class="position-relative">
      <div class="shape overflow-hidden text-light">
        <svg
          viewBox="0 0 2880 250"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M720 125L2160 0H2880V250H0V125H720Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>

    <section class="section bg-light">
      <div class="container">
        <div
          class="row my-md-5 pt-md-3 my-4 pt-2 pb-lg-5 mt-sm-0 pt-sm-0 justify-content-center"
        >
          <div class="col-12 text-center">
            <div class="section-title">
              <h4 class="title mb-4">Tem dúvida? Entre em contato!</h4>
              <a
                  v-if="!!urlWhatsapp"
                  :href="urlWhatsapp"
                  class="btn btn-primary mt-4"
                  target="_blank"
              >
                <i class="mdi mdi-whatsapp"></i> WhatsApp
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="position-relative">
      <div class="shape overflow-hidden text-footer">
        <svg
          viewBox="0 0 2880 250"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M720 125L2160 0H2880V250H0V125H720Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

import {
  HelpCircleIcon,
} from "vue-feather-icons";

import Pricing from "@/components/Pricing";
import BiddingSystemProcessService from "../services/BiddingSystemProcessService";
import date_to_br_day from "@/utils/date_to_br_day";
import date_to_br_month from "@/utils/date_to_br_month";

export default {
  components: {
    Carousel,
    Slide,
    HelpCircleIcon,
    Pricing
  },
  data() {
    return {
      searchTerm: '',
      processes: [],
      date_to_br_day: date_to_br_day,
      date_to_br_month: date_to_br_month,
    }
  },
  created() {
    this.getLastProcess()
  },
  computed: {
    urlPlataform() {
      return this.$store.getters['layout/urlPlataform'].replace('app-', 'app2-')
    },
    urlWhatsapp() {
      return this.$store.getters['layout/urlWhatsapp']
    },
    title() {
      return this.$store.getters['layout/title']
    },
    organizationName() {
      return this.$store.getters['layout/organizationName']
    },
    messageWhatsApp() {
      const organizationName = this.$store.getters['layout/title']

      return `${'https://api.whatsapp.com/send?phone=5599984449559&text=Olá, estou com dúvidas sobre o Portal de Licitação da ' + organizationName + '.'}`
    }
  },
  methods: {
    async search() {
      await this.$router.push({ name: 'processos', query: { searchTerm: this.searchTerm } })
    },

    async getLastProcess() {
      const params = {
        limit: 6
      }
      params['origin'] = window.location.host
      const response = await BiddingSystemProcessService.getAll(params)
      const {data} = response
      this.processes = data.data.data
    },
  },
};
</script>
<style scoped>
@media (max-width: 1439px) {
  .card-carousel-media  {
    /*min-height: 210px !important;*/
    /*max-height: 210px !important;*/
    padding: 0px !important;
  }

  .card-carousel-media-title  {
    font-size: 15px;
  }
}

@media (max-width: 1023px) {
  .card-carousel-media  {
    min-height: 210px !important;
    max-height: 210px !important;
    padding: 0px !important;
  }

  .card-carousel-media-title  {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .card-carousel-media  {
    min-height: 250px !important;
    max-height: 250px !important;
    padding: 0px !important;
  }

  .card-carousel-media-title  {
    font-size: 12px;
  }
}
</style>

